<template>
    <icon-base :width="size + 'px'" :fill="color" viewBox="0 0 250 250" style="enable-background:new 0 0 250 250;">
        <g>
            <path class="st5" d="M171.031,0.333H78.034c-13.085,0-23.731,11.244-23.731,25.066v199.201c0,13.821,10.646,25.066,23.731,25.066
                h93.931c13.085,0,23.731-11.244,23.731-25.066V26.397C195.697,12.026,184.631,0.333,171.031,0.333z M186.835,224.601
                c0,8.934-6.672,16.204-14.869,16.204H78.034c-8.197,0-14.869-7.27-14.869-16.204V25.399c0-8.934,6.672-16.204,14.869-16.204h12.043
                c1.788,5.171,6.648,8.908,12.427,8.908h44.993c5.778,0,10.638-3.737,12.427-8.908h11.108c8.713,0,15.804,7.716,15.804,17.201
                V224.601z"/>
            <path class="st5" d="M150.538,222.513H99.462c-2.475,0-4.48,2.006-4.48,4.48v0c0,2.474,2.005,4.48,4.48,4.48h51.077
                c2.475,0,4.48-2.006,4.48-4.48v0C155.019,224.519,153.013,222.513,150.538,222.513z"/>
        </g>
    </icon-base>
</template>

<script>
export default {
    name: 'iconCellphone',
    components: {
        iconBase: () => import('@/common/components/iconBase.vue')
    },
    props: {
        size: {
            type: Number,
            default: 20
        },
        color: {
            type: String,
            default: 'var(--main-navy)'
        },
        enableBackground: {
            type: Boolean
        }
    }
}
</script>